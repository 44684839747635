body{
  margin:0
}

.loader{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh
}

.profilebox{
  display: flex;
}

.heading{
  font-weight: 500;
  color:#9D9D9D ;
}

.offerIcon{
  margin-right: 5px;
  margin-top: 5px;
  width: 25px;
  height: 25px;
}

.profileIcon{
  margin-right: 5px;
  margin-top: 5px;
  width: 28px;
  height: 28px;
}
.card{
    text-align: center;
    padding: 20px;
  }
  
  .iconmargin{
    margin-bottom: 20px;
  }
 
  .display{
    display: flex;
    justify-content: space-between;
    height: 25px;
  }

  .flexCenter{
    display:flex;
    justify-content: center;
  }

  .boxreedemed{
    position: relative;
    left: 45px;
    background-color: #fdeae7;
    border-radius: 50%;
    width: 115px;
    height: 106px;
  }
  .reedemed{
    position: relative;
    left: 18px;
    top: 15px;
    font-weight: 600;
    font-size: 14;
    padding-top: 15px;
    color: #F26E5F;
  }

  .staricon{
    position: relative; 
    top: 11px; 
    margin-right: 7px;
  }

  .download{
    margin-top: 5px;
    width: 23px;
    height: 18px;
  }

  .frdBox{
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
  }
  .MuiTabs-flexContainer {
    justify-content: center;
  }

  .loyalyMonth{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 600;
  }

  .loyaltyHead{
    background-color: #5b5b5b;
    color: white;
    font-weight: 600;
  }

th,td{
  padding: 15px;
}

.margin{
 margin: 2px;
 margin-bottom: 0px;
}

.flexSpace{
  display: flex;
  justify-content: space-between;
}

.earned{
  color: #00A92B;
  font-weight: 600;
}

.burned{
  color: #ff4d4d;
  font-weight: 600;
}

.voidBill{
   color: #4e4e4e;
  font-weight: 600;
}

.loadingGif{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
}




